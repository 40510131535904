//@import "~bootstrap/scss/bootstrap";

html,
body,
.root {
	height: 100%;
	background-color: #f5f5f5;
}

.fh {
	height: 100%;
}

.btn.disabled {
	opacity: 0.2 !important;
}

.bmc-button img {
	height: 24px !important;
	width: 25px !important;
	margin-bottom: 1px !important;
	box-shadow: none !important;
	border: none !important;
	vertical-align: middle !important;
}
.bmc-button {
	padding: 7px 7px 7px 7px !important;
	line-height: 25px !important;
	text-decoration: none !important;
	display: inline-flex !important;
	color: #ffffff !important;
	background-color: #5f7fff !important;
	border-radius: 5px !important;
	border: 1px solid transparent !important;
	padding: 7px 15px 7px 10px !important;
	font-size: 22px !important;
	letter-spacing: 0.6px !important;
	box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;
	-webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
	margin: 0 auto !important;
	-webkit-box-sizing: border-box !important;
	box-sizing: border-box !important;
	white-space: nowrap;
}
.bmc-button:hover,
.bmc-button:active,
.bmc-button:focus {
	-webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
	text-decoration: none !important;
	box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
	opacity: 0.85 !important;
	color: #ffffff !important;
}
